<template>
  <!--begin::Advance Table Widget 9-->

  <!--begin::Table-->

  <tr class="text-center">
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ firstname }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ lastname }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ title }}</span>
    </td>
    <td class="table-mw-100">
      <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ dateandtime }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'sc-events-table',
  props: {
    firstname: {
      type: String,
      default: '',
    },
    lastname: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },
    dateandtime: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
</style>
