<template>
  <div>
    <div class="card-body pt-0 pb-3 pt-7 card card-custom card-stretch gutter-b">
      <div class="col-xxl-12 order-1 order-xxl-2">
        <div class="tab-content overflow-auto">
          <!--begin::Table-->
          <div class="table-responsive">
            <div class="card-toolbar mb-3">
              <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    :class="{ active: this.show === 'inbox' }"
                    data-toggle="tab"
                    @click="show = 'inbox'"
                  >
                    Inbox
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'sent' }"
                    @click="show = 'sent'"
                  >
                    Sent
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'trash' }"
                    @click="show = 'trash'"
                  >
                    Trash
                  </a>
                </li>
              </ul>
            </div>

            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                <thead>
                  <tr>
                    <th class="table-mw-100 text-center">First Name</th>
                    <th class="table-mw-100 text-center">Last Name</th>
                    <th class="table-mw-100 text-center">Title</th>
                    <th class="table-mw-100 text-center">Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  <sc-notifications-table
                    v-for="(item, i) in list"
                    :key="i"
                    :firstname="item.firstname"
                    :lastname="item.lastname"
                    :title="item.title"
                    :dateandtime="item.dateandtime"
                  />
                </tbody>
              </table>
              <b-pagination
                v-model="currentPage"
                :list="list"
                :per-page="perPage"
                aria-controls="my-table"
                align="center"
                class="mt-10"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ScNotificationsTable from '../../content/widgets/advance-table/sc-notifications-table.vue'

export default {
  name: 'notifications-overview',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Notifications' }])
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },
  data() {
    return {
      show: 'inbox',
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      default_photo: '',
      current_photo: null,
      currentPage: 1,
      perPage: 8,
      list: [
        {
          firstname: 'Canan',
          lastname: 'Gunhan',
          title: 'BMW',
          dateandtime: '01-01-2020',
        },
      ],
    }
  },
  components: {
    ScNotificationsTable,
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.current_photo = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
  },
}
</script>

<style>
.image-input-wrapper {
  width: 250px;
  height: 250px;
}

.table-mw-100 {
  min-width: 100px;
}
</style>
